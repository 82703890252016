import styled from 'styled-components'

import * as colorHelpers from '@pretto/bricks/components/colors'
import { g } from '@pretto/bricks/components/layout'

export const Container = styled.div`
  background-attachment: local, local, scroll, scroll;

  background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.legacy.colors.white.default} 40%,
      ${({ theme }) => colorHelpers.fade(theme.legacy.colors.white.default, 0)}
    ),
    linear-gradient(
      to left,
      ${({ theme }) => theme.legacy.colors.white.default} 40%,
      ${({ theme }) => colorHelpers.fade(theme.legacy.colors.white.default, 0)}
    ),
    radial-gradient(
      0 50%,
      farthest-side,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(20)} 40%,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(0)}
    ),
    radial-gradient(
      100% 50%,
      farthest-side,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(20)} 40%,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(0)}
    );

  background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.legacy.colors.white.default} 40%,
      ${({ theme }) => colorHelpers.fade(theme.legacy.colors.white.default, 0)}
    ),
    linear-gradient(
      to left,
      ${({ theme }) => theme.legacy.colors.white.default} 40%,
      ${({ theme }) => colorHelpers.fade(theme.legacy.colors.white.default, 0)}
    ),
    radial-gradient(
      farthest-side at 0 50%,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(20)} 40%,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(0)}
    ),
    radial-gradient(
      farthest-side at 100% 50%,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(20)} 40%,
      ${({ theme }) => theme.legacy.colors.neutral1.fade(0)}
    );

  background-position: 0 0, 100% 0, 0 0, 100% 0;
  background-repeat: no-repeat;
  background-size: ${g(5)} 100%, ${g(5)} 100%, ${g(1)} 100%, ${g(1)} 100%;
  overflow-x: auto;
  overflow-y: hidden;

  img {
    display: block;
  }
`
