import PropTypes from 'prop-types'

import { Container } from './styles'

const Latex = ({ src }) => (
  <Container>
    <img alt="" src={src} />
  </Container>
)

Latex.propTypes = {
  src: PropTypes.string.isRequired,
}

export default Latex
